@import "design/variables";
@import "design/template/mixin_templates";

/*-----------------------------
    VARIABLE DE COULEUR DU THEME
-------------------------------*/

$couleur_principale: #3B3B3B;
$couleur_principale_hover: #606060;
$blanc: #fff;
$gris: #ccc;
$noir: #000;

/*-----------------------------
    CORPS
-------------------------------*/
body {
    background-repeat: repeat;
    background:$couleur_principale;
    color:$blanc;
}

.content_p {
    background: $couleur_principale;
}

.fils-separation:nth-child(2) {
    color: $blanc;
}

/*-----------------------------
    ENTETE DE PAGE
-------------------------------*/
header {
    #blocContent {
        background: #111;
    }
    .slogan {
        color: $blanc;
    }
}

.produit_etiquette:after {
    border-color: $noir rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    h3, .h3{
        color:$blanc;
    }
}

.produit_etiquette {
    background: $noir;
}

.produit_etiquette2 {
    background: $noir;
    h3, .h3{
        color:$blanc;
    }
}

.produit_etiquette2:after {
    border-color: rgba(0,0,0,0) $noir rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    h3, .h3{
        color:$blanc;
    }
}

.links_p a{
    color:$blanc;
}

// Style génèrale du texte

h2, .h2 {
    color: $blanc;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: $couleur_principale;
    border-color: $couleur_principale;
}

.pagination > li > a, .pagination > li > span {
    color: $blanc;
}

.pagination.pull-right li.active a {
    color: $blanc !important;
}

.title_section a {
    color: $blanc;
}

h5, .h5 {
    border-bottom-color: $couleur_principale_hover;
}

/*-----------------------------
    NAVIGATION
-------------------------------*/

header .menu, .menu_burger {
    background-color: $couleur_principale;
}

#nav-principal {
    .navigation_link_p{
        color: $blanc;
    }
    .navigation_button_p{

        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $couleur_principale_hover;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
            .navigation_link_p{
                color: $blanc;
            }
        }
    }
    .sous_navigation_link_p{
        color: $blanc;
    }
    .sous_navigation_button_p{
        background: transparentize($couleur_principale, 0.1);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $couleur_principale_hover;
            > .sous_navigation_link_p{
                color: $blanc;
            }
        }
    }
}

.menu_burger {
    background-color: $couleur_principale;
    color: $blanc;
    text-decoration: none;
    &:hover, &.actif{
        background-color: $couleur_principale;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
        color: $blanc;
    }
}

// Fils d'ariane

nav.arianne {
    border-bottom-color: $couleur_principale_hover;
}

nav.arianne ul li a {
    text-decoration: none;
    color: $blanc;
}

/*-----------------------------
   SLIDER
-------------------------------*/
.wrapper-content .accueil_diaporama_template {

    .bx-wrapper .bx-pager .bx-pager-item > a {
        background: $blanc;

        &:hover, &.active {
            background: $couleur_principale;
        }
    }

    a.bx-prev, a.bx-next {

        &::before {
            color: $blanc;
        }

        &:hover::before {
            color: $blanc;
        }
    }

    .slide .slide-text .content-slide-text {

        > p {
            background: $couleur_principale;
            color: $blanc;
        }

        > a .slider-see-more {
            background: $couleur_principale;
            color: $blanc;

            &:hover {
                background: $blanc;
            }
        }

        .addcommentaires {
            background: $blanc;
        }
    }
}

/*-----------------------------
   CONTENU
-------------------------------*/

.wrapper-content {
    //background: $blanc;
}

/*-----------------------------
   BOUTIQUE
-------------------------------*/

#wrapper .template_boutique_accueil .produits,
#wrapper .template_boutique .produits {
    border: 1px solid $couleur_principale;

    .zoom {
        &::before {
            color: $blanc;
            background: $couleur_principale;
        }

        &::after {
            background: $couleur_principale;
        }
    }

    &.template_boutique_3 {
        .produits {
            border: 1px solid $couleur_principale;
        }

        .productContentWrap .nomprod {
            border-bottom: 1px dashed $couleur_principale;
        }
    }

    .note-produit {
        color: $couleur_principale;
    }
}

/*-----------------------------
   FICHE PRODUIT
-------------------------------*/

#wrapper .template_fiche_produit {
    .fa-star, .fa-star-o, .fa-star-half-o {
        color: $blanc;
    }

    .wrap-images {
        border: 1px solid $couleur_principale;
    }

    .wrap-description {

        .radio_unique {
            border: 1px solid $couleur_principale;
            color: $blanc;

            &:hover, &.radio-checked {
                border-color: $couleur_principale;
                color: $blanc;
                background: $couleur_principale;
            }
        }
    }

    .bloc-avis-clients {

        .moyenne_etoiles {
            color: $blanc;
        }

        .msg-informations {
            border-bottom: 1px dashed $couleur_principale;

            .noteComment {
                color: $blanc;
            }

            .time {
                color: $blanc;
            }
        }
    }

    &.template_fiche_produit_2 {
        #addcomment {
            box-shadow: 0 0 3px $gris;
            border: 1px solid $gris;
        }
    }

    &.template_fiche_produit_3 #fiche-produit {

        .wrap-description .details-descr-com {
            border: 1px solid $couleur_principale;

            .side-tabs > a {
                color: $blanc;

                &:hover, &.active {
                    background: $couleur_principale_hover;
                }
            }
        }
    }
}


#main-conteneur .wrapper-content #wrapper #fiche-produit .wrap-images {

    .previous, .bx-prev {

        &::before {
            color: $blanc;
        }

        &:hover::before {
            color: $blanc;
        }
    }

    .next, .bx-next {
        &::before {
            color: $blanc;
        }

        &:hover::before {
            color: $blanc;
        }
    }
}

/*-----------------------------
   PANIER
-------------------------------*/

.template-panier.template-panier-2 {

    .connexion p, .connexion p a, #header-panier {
        color: $blanc;
    }

    .count-live {
        background: $couleur_principale;
        color: $blanc;
    }
}

.template-panier.template-panier-3 {
    .connexion p, .connexion p a, #header-panier {
        color: $blanc;
    }
    .count-live {
        background: $blanc;
        color: $blanc;
    }
}

/*-----------------------------
   ALBUMS PHOTO
-------------------------------*/

#wrapper .template_album {
    .galerie {
        background: $couleur_principale;
        .mask{
            background: $couleur_principale;
        }
        .commentP p{
            color:$blanc;
            &::after {
                background: $blanc;
            }
        }
        .addcommentaires {
            color: $blanc;
        }
    }

    &.template_album_4 .galerie .commentP,
    &.template_album_5 .galerie .commentP {
        background: $couleur_principale;
        color: $blanc;
    }
}


.photo {
    border-top-color: $couleur_principale;
}

.mask > .commentaire, .galerie .addcommentaires {
    border-bottom-color: $blanc;
}

.mask > .commentaire a, .galerie .addcommentaires {
    text-decoration: none;
    color: $blanc;
}

.mask > h3,
.mask > .h3 {
    color: $blanc;
}

//  Bloc des élements d'un produit

.produits {
    border-top-color: $couleur_principale;
}

.triangle {
    border-left-color: $blanc;
}

.produits .remise {
    color: $blanc;
    background: $couleur_principale;
}

.fil-dariane ul li a {
    text-decoration: underline;
    color: $couleur_principale;
}

h3, .h3, .prix {
    color: $couleur_principale;
}

#wrapper .remise-produit {
    background: $couleur_principale;
    color: $blanc;
}

.oldprix {
    color: $blanc !important;
}

.content #fiche-produit .wrap-details h4,
.content #fiche-produit .wrap-details .h4 {
    color: $blanc;
    border-bottom-color: $couleur_principale_hover;
}

/*-----------------------------
    LIVRE D'OR
-------------------------------*/
.livre_dor {
    &.template_livreor_1 {
        .message_lo {
            border: 1px solid $couleur_principale;
        }
    }

    &.template_livreor_2 {
        .list-messages .message_lo .IDLO {
            background: $couleur_principale;
            color: $blanc;

            &:hover {
                background: $couleur_principale_hover;
            }
        }
    }

    &.template_livreor_3 {

        .list-messages .message_lo {
            border: 1px solid $couleur_principale;
        }

        .list-messages .message_lo:hover .contenuMsgLO .message::before,
        .list-messages .message_lo:hover .contenuMsgLO .message::after {
            color: $blanc;
        }
    }
}


.message_lo {
    border-left-color: $couleur_principale;
    border-bottom-color: $couleur_principale;
}

.message_lo:before {
    color: $blanc;
}

.message_lo .note {
    color: $blanc;
}

/*-----------------------------
    PIED DE PAGE
-------------------------------*/

.mention {
    background-color: $couleur_principale;
    color: $blanc;
}

.mention a {
    text-decoration: none;
    color: $blanc;
}

/*-----------------------------
    GESTION DES CLASSES OBJETS GENERALE
-------------------------------*/
/*
    * Gestion des photos gerer avec fancybox
    * sur les produits et galerie images
*/

.button, .bouton, .links_p .bouton, .links_p .button, .produits .addbasket, .links_p a.addmsglo, .btn-search, input[type="submit"], .form-newsletter input[type="submit"], .addmsglo, .links_p .addmsglo, .btn-search {
    color: $couleur_principale;
    background: $blanc;
    &:hover, &:focus{
        color:$blanc;
        background: $couleur_principale_hover;
    }
}

.contact_text {
    border-top-color: $couleur_principale_hover;
}


@media #{$large-and-down} {
    .template-panier.template-panier-2 #header-panier {
        background: $blanc;
    }
}

/*-----------------------------
FLUX PANIER
-------------------------------*/

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: $couleur_principale_hover;
    border-color: $couleur_principale_hover;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: $couleur_principale_hover;
    color: $couleur_principale_hover;

    a, span {
        color: $couleur_principale_hover;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}